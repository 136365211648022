<script>
import {satellitesImageDataTypeOptions} from '@/utils/enums';
import { upload } from '@/utils/firebase';
import gql from 'graphql-tag'
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid'

export default {
  data () {
    return {
      dialog: false,
      loadingCustomers: false,
      loadingFarms: false,
      loadingSave: false,
      valid: null,
      form: {},
      customers: [],
      farms: [],
      types: satellitesImageDataTypeOptions,

      validations: {
        customer: [(v) => !!v || 'Cliente é obrigatório'],
        farm: [(v) => !!v || 'Fazenda é obrigatório'],
        type: [(v) => !!v || 'Tipo de Coleta é obrigatório'],
        file: [(v) => !!v || 'Arquivo é obrigatório'],
      },
    }
  },

  async created () {
    this.$on('create', this.initCreate)

    this.customers = (await this.fetchCustomers()).map((item) => ({
      value: item.id,
      text: item.name,
    }));
  },

  methods: {
    initCreate () {
      this.form = {}
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    async save () {
      if (!this.$refs.form.validate()) return false;
      this.loadingSave = true
      try {
        await this.insert()
        Swal.fire({
          icon: 'success',
          text: 'Enviado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.dialog = false
        this.$emit('success')
      } catch (e){
        console.log(e)
        const error = e.graphQLErrors[0]
        if (error.extensions.code === 'business') {
          Swal.fire({
            icon: 'error',
            html: error.message.replace(/\n\n/g, '<br/>'),
          });
        }
      }
      this.loadingSave = false
    },

    async insert() {
      const path = `/images/${this.form.customer_id}/${this.form.farm_id}`
      const importCode = uuidv4()
      for (const file of this.form.file) {
        try {
          await this.insertImage(path, file, importCode)
        } catch (e){
          console.log(e)
          if (e.graphQLErrors) {
            const error = e.graphQLErrors[0]
            if (error.extensions.code === 'business') {
              Swal.fire({
                icon: 'error',
                html: error.message.replace(/\n\n/g, '<br/>'),
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              text: 'Erro ao enviar imagem. ' + e.message,
            });
          }
        }
      }
    },

    async insertImage(path, file, importCode) {
      const fileArr = file.name.split('.');
      const fileName = fileArr[0];
      const fileExt = fileArr[1];
      const capturedAt = new Date(`${fileName}GMT-0300`);
      if (capturedAt.toString() === 'Invalid Date') {
        throw new Error('Data inválida')
      }
      const url = await upload(file, `${path}/${fileName}-${this.form.type}.${fileExt}`);

      const form = {
        customer_id: this.form.customer_id,
        farm_id: this.form.farm_id,
        type: this.form.type,
        captured_at: capturedAt,
        file_path: url,
        import_code: importCode
      };

      return await this.$apollo.mutate({
        mutation: gql`
          mutation ($image: images_insert_input!) {
            insert_images_one(object: $image) {
              id
            }
          }
        `,
        variables: {
          image: form
        },
      });
    },

    async insert1() {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      const form = {
        farm_id: this.form.farm_id,
        type: this.form.type,
        fileBase64: await toBase64(this.form.file)
      }

      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($form: importSatellitesDataInput!) {
            import_satellites_data(data: $form) {
              success
              message
              errors
            }
          }
        `,
        variables: {
          form
        },
      });
      return res.data.import_satellites_data
    },

    async fetchCustomers() {
      this.loadingCustomers = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query customers {
              customers(order_by: [{ name: asc }]) {
                id
                name
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        this.loadingCustomers = false
        return result.data.customers;
      } catch (e) {
        console.error(e);
      }
      this.loadingCustomers = false
    },

    async populateFarms() {
      this.form.farm_id = null
      this.farms = (await this.fetchFarms()).map((item) => ({
        value: item.id,
        text: item.name,
      }));
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms ($customeId: uuid!) {
              farms(
                where: {
                  customer_id: { _eq: $customeId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
              }
            }
          `,
          variables: {
            customeId: this.form.customer_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingFarms = false
        return result.data.farms;
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },
  }
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
      <v-card>
        <v-card-title class="text-h5">
          Importar imagens
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="form.customer_id"
                :items="customers"
                :rules="validations.customer"
                :loading="loadingCustomers"
                label="Cliente"
                outlined
                persistent-placeholder
                validate-on-blur
                @input="populateFarms"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="form.farm_id"
                :items="farms"
                :rules="validations.farm"
                :disabled="!form.customer_id"
                :loading="loadingFarms"
                label="Fazenda"
                outlined
                persistent-placeholder
                validate-on-blur
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="form.type"
                :items="types"
                label="Tipo de coleta"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                ref="file"
                v-model="form.file"
                :rules="validations.file"
                label="Arquivo"
                placeholder="Clique para selecionar seu arquivo"
                accept="image/*"
                name="file"
                multiple
                counter
                show-size
                prepend-icon=""
                append-icon="mdi-paperclip"
                outlined
                persistent-placeholder
                validate-on-blur
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="success" :disabled="loadingSave" :loading="loadingSave" @click="save">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
